var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"communication-list"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("Communication"))),_c('v-chip',{staticClass:"ml-2",attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.totalItems)+" ")]),_c('v-spacer'),_c('export-excel',{attrs:{"options":_vm.downloadObject},on:{"refetch-data":function () { return _vm.fetchItems(true, 'communication'); }}})],1),_c('v-data-table',{staticClass:"fixed-table",attrs:{"headers":_vm.filters,"items":_vm.items,"options":_vm.options,"page":_vm.page,"server-items-length":_vm.totalItems,"loading":_vm.loading,"loading-text":_vm.$t('Loading data'),"no-data-text":_vm.$t('No data'),"no-results-text":_vm.$t('No result'),"mobile-breakpoint":250,"hide-default-footer":"","show-select":false,"dense":"","fixed-header":"","height":"500"},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
return [_c('header-filters',{attrs:{"headers":_vm.filters}})]}},{key:"item",fn:function(options){return [_c('Row',{attrs:{"options":options,"config":{ url: '/communications/action/', author: 'publication.author', manuscript: 'publication.manuscript', resource: 'Communication' }},on:{"refetch-data":_vm.fetchItems,"saved":_vm.saved},scopedSlots:_vm._u([{key:"item.completion_date",fn:function(ref){
var item = ref.item;
return [(item.completion_date && !item.completion_date.includes('0000'))?_c('span',[_vm._v(_vm._s(_vm.formatDateShort(item.completion_date || null, { month: 'numeric', day: 'numeric', year: 'numeric' })))]):_vm._e()]}},{key:"item.publication.author.full_name",fn:function(ref){
var item = ref.item;
return [(item.publication.author && item.publication.author.id)?_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.publication.author.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.publication.author.avatar ? '' : 'primary',"size":"30"}},[(item.publication.author.avatar)?_c('v-img',{attrs:{"lazy-src":require("@/assets/images/avatars/2.png"),"src":item.publication.author.avatar || require("@/assets/images/avatars/2.png")}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.publication.author.full_name)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'author-view', params : { id : item.publication.author_id } }}},[_vm._v(" "+_vm._s(item.publication.author.full_name)+" ")])],1)],1):_vm._e()]}},{key:"item.publication.author.old_author",fn:function(ref){
var item = ref.item;
return [(item.publication.author && item.publication.author.old_author)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircle)+" ")]):_vm._e()]}},{key:"item.is_reading",fn:function(ref){
var item = ref.item;
return [_c('span',[(item.is_reading)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircle)+" ")]):_vm._e()],1)]}},{key:"item.author.published",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(item.published)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircle)+" ")]):_vm._e()],1)]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [(item.country)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.country.name))])]):_vm._e()]}},{key:"item.huissier",fn:function(ref){
var item = ref.item;
return [_c('div',{style:(_vm.stateColor(item.communication_colors, 'huissier'))},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.openDialog(Object.assign({}, item, {action: 'paiement_huissier',activity_date: item.huissier,date_column:'huissier', 'activity_date_label': _vm.$t('Huissier'), info: _vm.$t('Confirmez-vous que l\'huissier a été fait ?')}))}}},'span',attrs,false),on),[(item.huissier && !item.huissier.includes('0000'))?_c('span',[_vm._v(_vm._s(_vm.formatDateShort(item.huissier || null, { month: 'numeric', day: 'numeric', year: 'numeric' })))]):_vm._e()])]}}],null,true)},[(!item.publication_records || (item.publication_records.filter(function (el){ return el.comment && el.type === 'paiement_huissier'; }).length === 0))?_c('span',[_vm._v(_vm._s(_vm.$t('Cliquez ici pour mettre à jour')))]):[_c('div',_vm._l((item.publication_records.filter(function (el){ return el.type === 'paiement_huissier'; })),function(precord){return _c('p',{key:precord.id},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(precord.user?precord.user.code:''))]),(precord.date_record)?_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' })))]):_vm._e(),_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(precord.comment))])])}),0)]],2),(!item.huissier || item.huissier.includes('0000'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","color":"primary","text":""},on:{"click":function($event){_vm.openDialog(Object.assign({}, item, {action: 'paiement_huissier', activity_date: item.huissier,date_column:'huissier', 'activity_date_label': _vm.$t('Huissier'), info: _vm.$t('Confirmez-vous que l\'huissier a été fait ?')}))}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckboxMarkedCircleOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Cliquez ici pour mettre à jour')))])]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return undefined}}],null,true)})]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}}),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{attrs:{"lg":"2","cols":"3"}},[_c('rows-per-page',{attrs:{"options":_vm.options}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"10","cols":"9"}},[_c('v-pagination',{attrs:{"total-visible":"6","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1),_c('v-dialog',{attrs:{"touchless":"","right":!_vm.$vuetify.rtl,"width":_vm.$vuetify.breakpoint.smAndUp ? 300 : '100%'},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("Confirm delete")))]),_c('v-divider'),_c('v-card-text',[_vm._v(_vm._s(_vm.$t("Are you sure to delete?")))]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","outlined":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("No"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"primary","loading":_vm.loading},on:{"click":_vm.deleteItem}},[_vm._v(" "+_vm._s(_vm.$t("Yes"))+" ")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.$vuetify.theme.currentTheme.error,"light":_vm.$vuetify.theme.dark},model:{value:(_vm.isSnackbarBottomVisible),callback:function ($$v) {_vm.isSnackbarBottomVisible=$$v},expression:"isSnackbarBottomVisible"}},[_vm._v(" "+_vm._s(_vm.snackBarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }